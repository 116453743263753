import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Button from '../components/button'

import SEO from '../components/seo'
import BlogPostItem from '../components/blog-post-item'
import SocialReviewsFullwidth from '../components/social-reviews-fullwidth'
import { Link } from 'gatsby'

import { TestimonialVideo } from '../components/testimonial_video'
import ContactForm from '../components/estimate-form'
import { StartWithAFreeQuote } from '../components/start-with-a-free-quote'

import Breadcrumbs from '../components/breadcrumbs'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { getValueTrackParams as getValueTrackingParams } from '../helpers/adRefTracking'

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
  align-items: stretch;
`

const Heading = styled.div`
  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

const TestimonialsPage = (props) => {
  const { campaigns } = getValueTrackingParams()


  const [selectedTestimonialsStorage, setSelectedTestimonialsStorage] = useLocalStorage('selectedTestimonials', '')
  const [selectedFilters, updateSelectedFilters] = useState(selectedTestimonialsStorage ? selectedTestimonialsStorage : [])


  const [hasCategoryViewRestriction, setHasCategoryViewRestriction] = useState(false)
  const [hasCategoryViewRestrictionProjects, setHasCategoryViewRestrictionProjects] = useState([])

  const onFilterSelect = (e) => {
    if (e.target.dataset.filterId == '1-all-items') {
      updateSelectedFilters([])
      setSelectedTestimonialsStorage([])
      return
    }
    if (!selectedFilters.includes(e.target.dataset.filterId)) {
      updateSelectedFilters([...selectedFilters, e.target.dataset.filterId])
      setSelectedTestimonialsStorage([...selectedFilters, e.target.dataset.filterId])
    } else {
      updateSelectedFilters(
        selectedFilters.filter((item) => item !== e.target.dataset.filterId)
      )
      setSelectedTestimonialsStorage(selectedFilters.filter((item) => item !== e.target.dataset.filterId))

    }
  }

  //check to see if limiting categories to show
  useEffect(() => {
    if (props.location && props.location.search && props.location.search.includes('?categories') || (campaigns && campaigns.length > 0 && (campaigns == 'siding' || campaigns == 'remodels'))) {
      let categoriesToShow = []

      //if a campaign is set, restrict viewable categories
      if (campaigns && campaigns.length > 0) {
        if (campaigns == 'siding') {
          categoriesToShow = ['siding', 'windows']
        } else if (campaigns == 'remodels') {
          categoriesToShow = ['kitchen', 'bathroom']
        }
      }

      //url params can override viewable categories
      if (props.location.search.includes('?categories')) {
        categoriesToShow = [...(props.location.search.split('?categories='))[1].split(',')]
      }

      setHasCategoryViewRestriction(data.allContentfulTestimonialCategory.edges.filter(cat => categoriesToShow.includes(cat.node.projectCategoryAlias.toLowerCase())))

      let viewableTestimonials = []
      data.allContentfulTestimonialVideoPost.edges.forEach(project => {
        project.node.category.forEach(category => {
          if (categoriesToShow.includes(category.projectCategoryAlias.toLowerCase())) {
            viewableTestimonials.push(project)
          }
        })

      });

      setHasCategoryViewRestrictionProjects([...viewableTestimonials])
    }
  }, [])

  const data = useStaticQuery(graphql`query getAllTestimonialVideos {
    allContentfulTestimonialVideoPost(sort: { fields:[createdAt],order:DESC}) {
      edges {
        node {
          id
          slug
          createdAt
          category {
            id
            name
            slug
          }
          video {
            id
            title
            file {
              url
              contentType
            }
          }
          projectReference {
            slug
          }
          thumbnail {
            id
            file {
              url
            }
            gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
          }
        }
      }
    }
    contactFormSideImage: contentfulAsset(
      title: { eq: "Kansas City Siding Contractor" }
    ) {
      id
      title
      gatsbyImageData
    }
    allContentfulTestimonialCategory {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }`)

  return (
    <>
      <Layout pageProps={props}>
        <div className="border-b border-gray-100">
          <nav
            className="flex container mx-auto px-4 py-3"
            aria-label="Breadcrumb"
          >
            <Breadcrumbs
              links={[
                { to: '/', label: 'Home' },
                { to: props.location.pathname, label: 'Testimonials' },
              ]}
            />
          </nav>
        </div>
        <SEO
          title={'Testimonials | US Quality Construction'}
          description={
            'US Quality Construction Video Testimonials. See what some of our customers have to say about us.'
          }
        />
        <Heading style={{ paddingTop: '1em' }}>
          <h1>Video Testimonials</h1>
          <p>See what some of our customers have to say about us.</p>
          <div
            className="container mx-auto text-center py-2 px-0"
            // ref={hiddenVTRef}
          >
            {[
              {
                node: {
                  id: '1-all-items',
                  name: 'All Projects',
                  slug: '',
                },
              },
              ...data.allContentfulTestimonialCategory.edges,
            ].map(
              ({ node: { id, name, projectCategoryAlias, slug } }) => (
                <div
                  onClick={onFilterSelect}
                  className={`relbtn text-sm ${
                    selectedFilters.includes(id) ||
                    (id == '1-all-items' && selectedFilters.length == 0)
                      ? 'active'
                      : ''
                  }`}
                  data-filter-id={id}
                >
                  {projectCategoryAlias ? projectCategoryAlias : name}
                </div>
              )
            )}
          </div>
        </Heading>
        <BlogPostsLayoutContainer className="container pt-2">
          {data.allContentfulTestimonialVideoPost.edges
            .filter((item) => {
              return selectedFilters.length > 0
                ? item.node.category.filter(({ id }) =>
                    selectedFilters.includes(id)
                  ).length > 0
                : true
            })
            .map(({ node: testimonialVideo }, i) => (
              <TestimonialVideo
                columnsClassName="col-lg-4"
                {...testimonialVideo}
                showTitle
              />
            ))}
        </BlogPostsLayoutContainer>
        <StartWithAFreeQuote {...props} data={data} />
        <SocialReviewsFullwidth modalLinkTo={`/reviews/all/`} />
      </Layout>
    </>
  )
}

export default TestimonialsPage
